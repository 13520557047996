.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #FF2E63;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #FF2E63;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #FF2E63, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

.projects-grid {
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 10%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(18em,1fr));
    grid-gap: 2em;
}

.project {
    padding: 2em;
    margin: 0 auto;
    text-align: center;
    background-color: gainsboro;
    border-radius: 10px;
    transition-duration: 300ms;
}

.project:hover {
    transform: scale(1.1);
}

.project img {
    width: 300px;
}

.project h3 {
    font-size: 20px;
}

ul.skills-used {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.2em 0;
    padding: 0;
    font-family: monospace;
}

.skills-used li {
    list-style: none;
    margin: 0.5em;
    font-weight: 500;
    font-size: 1rem;
    font-family: monospace;
}

.app-launch svg {
    height: 20px;
    padding: 5px;
    transition-duration: 300ms;
}

.container.portfolio-page p {
    color: #444;
}

.app-launch a svg:hover {
    color: #FF2E63;
    transform: scale(1.2);
}

.project {
    width: 20rem;
}
.project img {
    width: 18em;
}

@media screen and (max-width: 900px) { 
    .project {
        width: 20rem;
    }
    .project img {
        width: 18em;
    }
    .portfolio-page h1.page-title {
        margin-left: 0;
  
    }
}